import React from 'react'
import { Helmet } from 'react-helmet'
import SEO from '../components/seo'
import TDSPolicyPoker from '../components/TDSPolicy/TDSPolicyPoker'
import { POKER_TDS } from '../components/internal-links'
import { Breadcrumb } from '../components/Breadcrumbs/Breadcrumbs'
import Layout from '../components/layout'
import {
  pokerOneLinksPokerPage,
  pokerOneLinksStaticPage,
} from '../components/one-links'
import {
  generateBreadcrumbsSchema,
  generateFaqSchema,
  generateOrgSchema,
  generateWebsiteSchema,
} from '../lib/generate-schema'
import { pokerTDSFaqs } from '../components/FAQs/faqs-list'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Poker TDS Policy',
    url: POKER_TDS,
  },
]

const TDSPoker: React.FC = () => (
  <Layout
    showBreadcrumbs
    breadcrumbs={breadcrumbs}
    showPokerForm
    pokerOneLink={pokerOneLinksStaticPage}
  >
    <Helmet>
      {process.env.GATSBY_STAGING === 'true' && (
        <meta name="robots" content="noindex" />
      )}
    </Helmet>
    <SEO
      title="Mega Poker TDS Policy"
      description="Read about the poker tds policy at Mega. Get to know about data collection, usage, and protection by Mega. Click to know more!"
      organizationSchema={generateOrgSchema()}
      websiteSchema={generateWebsiteSchema()}
      breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
      faqSchema={generateFaqSchema(pokerTDSFaqs)}
    />
    <TDSPolicyPoker />
  </Layout>
)

export default TDSPoker
